<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="9">
        <div>
          <i class="position-absolute z-index-9 fa add-participant-class fa-user-plus"></i>
          <d-select-input-search
            :key="resetFieldKey"
            :place-holder="$t('admin.user-club.placeholder-input')"
            :options="choiceUserClubs"
            @on:keydown="onKeyDown"
            @on:selected="changeSelectUserClub"
          />
        </div>
      </b-col>
      <b-col cols="3" class="mb-2">
        <div class="d-btn-small btn text-center d-btn-primary btn-full-width" v-on:click="addUserRoute">
          {{ $t('admin.user-club.new-admin.new') }}
        </div>
      </b-col>
      <template>
        <b-col
          cols="12"
          :key="'selected-member' + i"
        >
          <d-table
            :key="'users-club-table'"
            :tablefields="tableFields"
            :per-page="itemsPerPage"
            :totalRows="totalRows"
            :items="items"
            :is-busy="isBusy"
            :show-pagination="true"
            @current-page:update="onCurrentPageUpdate"
            @on:data-table-btn:edit="onEdit"
            @on:data-table-btn:delete="onDelete"
          />
        </b-col>
      </template>
    </b-row>
  </div>
</template>
<script>
import {getAll, getUserAccessProfiles, putUser} from "@api/services/user-club/user-club.api";
import {getUsersClub, putUserClub} from "@api/services/client/client.api";
import {fromIdToIriReference} from "@/utils/form";
import User from "@/classes/User";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    choiceUserClubs: [],
    users: [],
    search: '',
    filter: '',
    members: [],
    timer: null,
    showAddForm: false,
    isBusy: true,
    itemsPerPage: 10,
    totalRows: 0,
    resetFieldKey: 0,
    currentPage: 1,
    items: [],
    userProfiles: [],
  }),
  props: {
    club: {
      type: Object,
      default: '',
    },
    family: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    VSelect: () => import('vue-select')
  },
  computed: {
    tableFields() {
      return [
        {key: "username", label: this.$t('admin.user-club.username'), sortable: true},
        {key: "fullName", label: this.$t('admin.user-club.fullname'), sortable: true},
        {key: "email", class: this.alignCellsToLeft, label: this.$t('admin.user-club.email'), sortable: true},
        {key: "phoneNumber", label: this.$t('admin.user-club.phone')},
        {key: "accessProfile", label: this.$t('admin.user-club.accessProfile')},
        {key: "actions", label: this.$t('admin.user-club.action')},
      ];
    }
  },
  methods: {
    changeSelectUserClub(event) {
      if (event) {
        const user = event.item
        user.clubs.push(this.club['@id']);
        let userAccessProfiles = [];

        for (const accessProfile of user.accessProfiles) {
          userAccessProfiles.push(accessProfile['@id']);
        }

        const adminProfile = this.userProfiles[0]["@id"];

        if (userAccessProfiles.length > 0) {
          const findAdminProfile = userAccessProfiles.find(el => el === adminProfile);

          if (false === isNotUndefinedAndNotNull(findAdminProfile)) {
            userAccessProfiles.push(adminProfile);
          }
        } else {
          userAccessProfiles = [adminProfile];
        }

        user.accessProfiles = userAccessProfiles;

        putUserClub(event.item)
          .then(() => {
            this.$vs.notify({
              time: 10000,
              title: this.$t('general.actions.notification'),
              text: this.$t('admin.user-club.new-admin.admin-added'),
              color: 'success',
              position: 'top-right'
            });
            this.resetFieldKey++;
            this.loadUserAdmin();
          }).catch(err => {
          console.errror('error PUT User club : ', err);
        });
      }
    },
    loadUserProfiles() {
      getUserAccessProfiles(60, 1, '', this.club["@id"])
        .then((response) => {
          this.userProfiles = response.data['hydra:member'].sort((b, a) => a.permission - b.permission);
        })
      ;
    },
    onKeyDown(filter) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadClubUsers(filter);
      }, 400);
    },
    isUserAlreadyAdded(user) {
      return this.items.find(item => item.id === user.id)
    },
    loadClubUsers(filter) {
      this.choiceUserClubs = [];
      getUsersClub(8, 1, filter)
        .then((response) => {

          for (const user of response.data['hydra:member']) {
            user['@id'] = fromIdToIriReference('/clubs/clients', user.id);

            if (!this.isUserAlreadyAdded(user)) {
              this.choiceUserClubs.push({
                label: user.lastName + ' ' + user.firstName,
                id: user.id,
                item: user,
                iri: user['@id'],
              });
            }
          }
        })
    },
    addUserRoute(user) {
      if (!user) {
        user = new User();
        user.clubs = [this.club["@id"]]
      }
      this.$router.push({name: 'user', params: {idUser: user.id}})
    },
    onCurrentPageUpdate(e) {
      this.currentPage = e;
      this.loadUserAdmin();
    },
    loadUserAdmin() {
      this.isBusy = true;
      this.items = [];
      getAll(this.club.id, this.currentPage, this.itemsPerPage)
        .then(resp => {
          if (resp) {
            this.totalRows = resp.data['hydra:totalItems'];

            resp.data['hydra:member'].forEach(item => {
              const userAdmin = new User(item, {serialize: true})

              userAdmin.fullName = item.lastName + ' ' + item.firstName;
              userAdmin.accessProfile = this.getUserClubAccessProfile(item);
              userAdmin.actions = item.clubs.length === 1 ? ['edit'] : ['edit', 'delete'];

              this.items.push(userAdmin);
            });
          }
          this.isBusy = false;
        }).catch(err => {
        this.isBusy = false;
      });
    },
    getUserClubAccessProfile(item) {
      let string = '';

      for (let i = 0; i < item.accessProfiles.length; i++) {
        let profile = item.accessProfiles[i];

        if (profile.club === this.club['@id']) {
          if (string === '') {
            string = profile.name
          } else {
            string += ', ' + profile.name;
          }
        }
      }

      return string;
    },
    onEdit(event) {
      this.addUserRoute(event)
    },
    onDelete(event) {
      const findUserClubToRemoveIndex = event.clubs.findIndex(club => club === this.club['@id']);

      if (findUserClubToRemoveIndex !== -1) {
        event.clubs.splice(findUserClubToRemoveIndex, 1);

        putUser(event.id, {clubs: event.clubs})
          .then(() => {
            this.$vs.notify({
              time: 10000,
              title: this.$t('general.actions.notification'),
              text: this.$t('admin.user-club.new-admin.admin-deleted'),
              color: 'success',
              position: 'top-right'
            });
            this.loadUserAdmin()
          })
      }
    }
  },
  created() {
    this.loadUserAdmin();
    this.loadUserProfiles();
  }
}
</script>

<style lang="scss" scoped>

.add-participant-class {
  margin-top: 1.5pt;
  font-size: 20px;
  color: #0c5460
}

</style>
